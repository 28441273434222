import $ from 'jquery';

export default {
  detectTouchSwipe: function(el, nextCb, prevCb) {
    var startPoint=null;
    var endPoint=null;
    var treshold=40;
    $(el).on("touchstart", function(e) {
      if (e.originalEvent.touches.length==1) {
        startPoint=[e.originalEvent.touches[0].clientX,
                    e.originalEvent.touches[0].clientY];
      }
    });
    $(el).on("touchmove", function(e) {
      if (e.originalEvent.touches.length==1) {
        endPoint=[e.originalEvent.touches[0].clientX,
                  e.originalEvent.touches[0].clientY];
      }
    });
    $(el).on("touchend", function(e) {
      if (e.originalEvent.touches.length!=0)
        return;
      if (!startPoint || !endPoint)
        return;
      var angle=Math.abs(endPoint[0]-startPoint[0])>Math.abs(endPoint[1]-startPoint[1]);
      if (endPoint[0]<startPoint[0]-treshold && angle)
        nextCb();
      if (endPoint[0]>startPoint[0]+treshold && angle)
        prevCb();
      startPoint=null;
      endPoint=null;
    });
  },
};
