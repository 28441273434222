import jQuery from 'jquery';

jQuery(function($) {
  
  var els=[
    ".home-news ul",
    ".home-sliste ul",
    ".my-egov-page .activity-list ul",
  ];
  
  $(els.join(", ")).each(function() {
    var scrollBar=$("<div />", {"class": "scrollbar"});
    var scrollHandle=$("<span />").appendTo(scrollBar);
    $(this).after(scrollBar);
    
    $(this).on("scroll", function() {
      var scrollWidth=this.scrollWidth;
      var innerWidth=$(this).innerWidth();
      var scrollLeft=$(this).scrollLeft();
      var width=innerWidth/scrollWidth*100;
      var left=scrollLeft/scrollWidth*100;
      scrollHandle.css("margin-left", left+"%").css("width", width+"%");
    }).trigger("scroll");
  });
  
  
  $("header nav").each(function() {
    $(this).find(".has-child").each(function() {
      var back=$("<li />", {"class": "back"});
      var link=$("<a />", {html: $(this).find(">a").html()}).appendTo(back);
      $(this).find(">ul").prepend(back);
    });
  });
  
  var currentStep=0;
  var updateCurrentStep=function() {
     $("header .nav").animate({scrollLeft: currentStep*window.innerWidth+30}, 300);
  }
  $("header nav li.has-child>a").click(function(e) {
    if (window.innerWidth<=990) {
      e.preventDefault();
      $(this).closest("ul").find(">li").removeClass("current");
      $(this).closest("li").addClass("current");
      currentStep++;
      updateCurrentStep();
    }
  });
  $("header nav li.back>a").click(function(e) {
    if (window.innerWidth<=990) {
      e.preventDefault();
      currentStep--;
      updateCurrentStep();
      var $this=$(this);
      setTimeout(function() {
        $this.closest("ul").find(">li").removeClass("current");
        $this.closest("ul").closest("li").removeClass("current");
      }, 300);
    }
    
  });
  
  

  
});
