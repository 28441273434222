import jQuery from 'jquery';

jQuery(function($) {
  var btns=$(".my-egov-page .user-info .btn,"+
             ".login-box .form-row .second,"+
             ".new-comment button,"+
             ".activity-details .lgbtn,"+
             // ".side-split > aside ul.single li.active,"+ /* TODO */
             ".side-institution a.button,"+
             ".notifications-form button,"+
             ".gen-form .form-row button,"+
             ".activity-details .lgbtn.lg2,"+
             ".login-box .form-row .third"+
             ".side-split > aside .new-doc");
  
  btns.each(function() {
    var content=$(this).html();
    $(this).html("<span>"+content+"</span><span></span>");
  });
  
});
