import jQuery from 'jquery';
var $=jQuery;

if ('pushState' in window.history) {
  
  var goToDynamicPage=function(url, popped) {
    var loadingBox=jQuery("<div />", {"class": "loading"});
    jQuery("body").append(loadingBox);

    jQuery.get(url, function(data) {
      loadingBox.remove();
      
      var html=jQuery(data);
      
      var isChild=html.find(".side-split").hasClass("three-split");
      $(".side-split>aside").html(html.find(".side-split>aside").html());
      $(".side-split>div.sub-list").html(html.find(".side-split>div.sub-list").html());
      if (isChild) {
        $(".side-split").addClass("three-split");
        $(".side-split>div:last-child").html(html.find(".side-split>div:last-child").html());
      } else {
        $(".side-split").removeClass("three-split");
//         setTimeout(()=>{
//           $(".side-split>div:last-child").html("");
//         }, 500);
      }
      
      
      
      
      jQuery(".breadcrumbs ul").html(html.filter(".breadcrumbs").find("ul").html());
      
      var title=data.match(/<title>([^<]+)/, data);
      if (title)
        title=title[1];
      document.title=title;
      
      if (!popped)
        jQuery("html, body").animate({scrollTop: 0}, 500);
      
      if (!popped)
        window.history.pushState(null, title, url);
    }, "html");
  }
  
  jQuery(document).on("click", "a.dyn-link", function(e) {
    e.preventDefault();
    var href=jQuery(this).attr("href");
    goToDynamicPage(href);
  });
  
  jQuery(window).on("popstate", function(e) {
    goToDynamicPage(window.document.location.href, true);
  });
  
}

var convertToLatin=function(str) {
  str=(str || "")+"";
  str=str.toLowerCase();
  var map={
    "а":"a", "б":"b", "в":"v", "г":"g", "д":"d", "ђ":"dj", "е":"e", "ж":"z", 
    "з":"z", "и":"i", "ј":"j", "к":"k", "л":"l", "љ":"lj", "м":"m", "н":"n", 
    "њ":"nj", "о":"o", "п":"p", "р":"r", "с":"s", "т":"t", "ћ":"ć", "у":"u", 
    "ф":"f", "х":"h", "ц":"c", "ч":"c", "џ":"dz", "ш":"s",
    "š":"s", "đ":"dj", "č":"c", "ć":"c", "ž":"z" 
  };
  str=str.replace(/./g, (match)=>map[match] || match);
  return str;
}

jQuery(document).on("keyup", ".qsear input", function(e) {
  var list=jQuery(this).closest("form").parent().find(">ul");
  var value=jQuery(this).val();
  list.find(">li").each(function() {
    
    if (!value || convertToLatin(jQuery(this).html()).indexOf(convertToLatin(value))!=-1) {
      jQuery(this).slideDown(300);
    } else {
      jQuery(this).slideUp(300);
    }
  });
});
