import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import Cookies from 'js-cookie';

jQuery(function($) {
  $(".activity-list").each(function() {
    var list=$(this).find(">ul");
    var next=$(this).find("a.next");
    var prev=$(this).find("a.prev");
    prev.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()-width}, 300);
    });
    next.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()+width}, 300);
    });
  });
  
  
  $(".new-doc").click(function(e) {
    e.preventDefault();
    $("#add-popup.form-popup").addClass("visible");
    $("#add-popup.form-popup form")[0].reset();
  });
  $(".doc-list .ed").click(function(e) {
    e.preventDefault();
    var id=$(this).closest("li").attr("data-id");
    $("#edit-popup.form-popup").addClass("visible");
    $("#edit-popup.form-popup form")[0].reset();
  });
  $(".form-popup .close, .form-popup .skip").click(function(e) {
    e.preventDefault();
    $(".form-popup").removeClass("visible");
    Cookies.set("active-muni", "-1", {path: "/"});    
    location.reload();
  });
  
  
  $(".datepicker").each(function() {
    var dateInput=$(this).find("input");
    $(this).find(".datepicker-ui").datepicker({
      dateFormat: "dd.mm.yy.",
      dayNames: window.dayNames,
      dayNamesMin: window.dayNames.map((a)=>a.substring(0,2)),
      dayNamesShort: window.dayNames.map((a)=>a.substring(0,3)),
      monthNames: window.monthNames,
      monthNamesShort: window.monthNames.map((a)=>a.substring(0,3)),
      onSelect: function(newDate) {
        dateInput.val(newDate);
      },
    }).datepicker("setDate", dateInput.val());
  });
  
  
  $("a[data-confirm]").click(function(e) {
    if (!confirm($(this).attr("data-confirm")))
      e.preventDefault();
  });
});
