import jQuery from 'jquery';
var $=jQuery;

var initNotifications=function() {
  if ($(".notifications").length)
    return;
  var notifications=$("<ul />", {"class": "notifications"}).appendTo("body");
}

window.showNotification=function(message, type) {
  initNotifications();
  type=type || "success";
  var newNotification=$("<li />", {"class": type, html: message});
  newNotification.appendTo($(".notifications"));
  setTimeout(()=>{
    newNotification.addClass("dismissed");
  }, 9500);
  
  setTimeout(()=>{
    newNotification.remove();
  }, 10000);
}
